import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { ResponsiveAttribute, getResponsiveCSSFromProps } from "../utils";

import { StyledGrid } from "./Grid.styled";

type GridProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    display?: ResponsiveAttribute<"grid" | "inline-grid">;
    asHTMLTag?: string;
    children?: React.ReactNode;
    gridAutoRows?: ResponsiveAttribute<string>;
    gridTemplateColumns?: ResponsiveAttribute<string>;
    gridTemplateRows?: ResponsiveAttribute<string>;
    gridColumnStart?: ResponsiveAttribute<string>;
    gridColumnEnd?: ResponsiveAttribute<string>;
    gridTemplateAreas?: ResponsiveAttribute<string>;
    gridTemplate?: ResponsiveAttribute<string>;
    gap?: ResponsiveAttribute<number | string>;
    gridGap?: ResponsiveAttribute<string>;
    columnGap?: ResponsiveAttribute<string>;
    rowGap?: ResponsiveAttribute<string>;
    justifyItems?: ResponsiveAttribute<"start" | "end" | "center" | "stretch">;
    alignItems?: ResponsiveAttribute<"start" | "end" | "center" | "stretch">;
    justifyContent?: ResponsiveAttribute<
      | "start"
      | "end"
      | "center"
      | "stretch"
      | "space-around"
      | "space-between"
      | "space-evenly"
    >;
    alignContent?: ResponsiveAttribute<
      | "start"
      | "end"
      | "center"
      | "stretch"
      | "space-around"
      | "space-between"
      | "space-evenly"
    >;
  };

const Grid = React.forwardRef<HTMLDivElement, GridProps>(
  (
    {
      className,
      children,
      style,
      display = "grid",
      asHTMLTag = "div",
      gridAutoRows,
      gridTemplateColumns,
      gridTemplateRows,
      gridColumnStart,
      gridColumnEnd,
      gridTemplateAreas,
      gridTemplate,
      gridGap,
      gap,
      columnGap,
      rowGap,
      justifyItems,
      alignItems,
      justifyContent,
      alignContent,
      ...interactionEvents
    },
    ref
  ) => {
    return (
      <StyledGrid
        ref={ref}
        {...interactionEvents}
        as={asHTMLTag as "div"}
        className={classNames(className)}
        style={style}
        responsiveCss={getResponsiveCSSFromProps({
          display,
          gridAutoRows,
          gridTemplateColumns,
          gridTemplateRows,
          gridColumnStart,
          gridColumnEnd,
          gridTemplateAreas,
          gridTemplate,
          gridGap,
          gap,
          columnGap,
          rowGap,
          justifyItems,
          alignItems,
          justifyContent,
          alignContent,
        })}
      >
        {children}
      </StyledGrid>
    );
  }
);

Grid.displayName = "Grid";

export default Grid;

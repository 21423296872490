import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import { Flex, Grid } from "../components";
import { styled } from "styled-components";

const StyledMainContainer = styled.div`
  background: black;
  height: 100%;
`;

const StyledVideo = styled.video`
  border-radius: 0 0 20px 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledProgressContainer = styled.div`
  position: relative;
  border-radius: 8px;
  height: 8px;
  width: 100%;
  background: white;
`;

const StyledProgress = styled.div`
  position: absolute;
  border-radius: 8px;
  height: 8px;
  width: 50%;
  background: #2e89a7;
`;

const Video: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className="no-bottom-offset">
        <StyledMainContainer>
          <Grid gridTemplateRows="1fr auto" style={{ height: "100%" }}>
            <StyledVideo autoPlay loop muted>
              <source src="/assets/samples/full.mp4" type="video/mp4" />
            </StyledVideo>
            <div style={{ padding: "20px 20px 80px 20px" }}>
              <Flex flexDirection="column" gap={12}>
                <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                  Exercise Title
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  30s
                </div>
                <StyledProgressContainer>
                  <StyledProgress />
                </StyledProgressContainer>
              </Flex>
            </div>
          </Grid>
        </StyledMainContainer>
      </IonContent>
    </IonPage>
  );
};

export default Video;

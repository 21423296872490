import React from "react";
import { styled } from "styled-components";
import { Grid } from "../Grid";

const StyledVideoPreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContainer = styled.div`
  position: relative;
  width: 90%;
  aspect-ratio: 16 / 16;
  border-radius: 20px;
  box-shadow: 0px 0px 40px 20px rgba(0, 0, 0, 0.75);
`;

const StyledVideoPreview = styled.video`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`;

const StyledVideoOverlay = styled.div`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  z-index: 1;
  display: grid;
  grid-template-rows: 2fr auto auto;
  gap: 20px;
  padding: 20px;
`;

const StyledOverlayTitle = styled.div`
  font-weight: bold;
  color: white;
  font-size: 24px;
`;

const StyledTimeSelector = styled.div`
  border-radius: 10px;
  background: white;
  color: black;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
`;

interface ExercisePreviewProps {
  onClose: () => void;
  onPickTime: (time: number) => void;
}

export default function ExercisePreview({
  onClose,
  onPickTime,
}: ExercisePreviewProps) {
  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <StyledVideoPreviewContainer onClick={handleClose}>
      <StyledContainer>
        <StyledVideoPreview autoPlay loop muted>
          <source src="/assets/samples/default.mp4" type="video/mp4" />
        </StyledVideoPreview>
        <StyledVideoOverlay>
          <div />
          <div>
            <StyledOverlayTitle>Exercise Title</StyledOverlayTitle>
          </div>
          <Grid gridTemplateColumns="1fr 1fr 1fr 1fr" gap={10}>
            <StyledTimeSelector onClick={() => onPickTime(30)}>
              30s
            </StyledTimeSelector>
            <StyledTimeSelector onClick={() => onPickTime(60)}>
              1m
            </StyledTimeSelector>
            <StyledTimeSelector onClick={() => onPickTime(120)}>
              2m
            </StyledTimeSelector>
            <StyledTimeSelector onClick={() => onPickTime(300)}>
              5m
            </StyledTimeSelector>
          </Grid>
        </StyledVideoOverlay>
      </StyledContainer>
    </StyledVideoPreviewContainer>
  );
}

import React, { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Tab1.css";
import styled from "styled-components";
import { Flex, Grid } from "../components";
import Tile from "../components/Tile/Tile";
import ExercisePreview from "../components/ExercisePreview/ExercisePreview";
import { useHistory } from "react-router-dom";

const StyledHomePageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const StyledTitleContainer = styled.div`
  height: 160px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 34px;
  letter-spacing: 1px;
  line-height: 30px;
  color: white;
`;

const StyledPageContainer = styled.div`
  border-radius: 20px 20px 0 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #f2f2f2 100%);
  padding: 20px;
  color: #131329;
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const Tab1: React.FC = () => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);

  const handleGoToVideo = () => {
    history.push("/video");
  };

  const handleClick = () => {
    setOpened(true);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="no-bottom-offset">
        {opened && (
          <ExercisePreview
            onClose={() => setOpened(false)}
            onPickTime={handleGoToVideo}
          />
        )}
        <StyledHomePageContainer>
          <StyledTitleContainer>
            <StyledTitle>
              Posture
              <br />
              Intelligence
            </StyledTitle>
          </StyledTitleContainer>
          <StyledPageContainer>
            <Flex flexDirection="column" gap={24}>
              <div>
                <StyledSectionTitle>Back pain relief</StyledSectionTitle>
                <Grid
                  gridTemplateColumns="1fr 1fr"
                  gap={20}
                  style={{ marginTop: "20px" }}
                >
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                </Grid>
              </div>
              <div>
                <StyledSectionTitle>Posture correction</StyledSectionTitle>
                <Grid
                  gridTemplateColumns="1fr 1fr"
                  gap={20}
                  style={{ marginTop: "20px" }}
                >
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                  <Tile onClick={handleClick} />
                </Grid>
              </div>
            </Flex>
            <div style={{ height: "100px" }}></div>
          </StyledPageContainer>
        </StyledHomePageContainer>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;

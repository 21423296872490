import React from "react";

interface TileProps {
  onClick: () => void;
}

export default function Tile({ onClick }: TileProps) {
  return (
    <div onClick={onClick}>
      <img
        src="/assets/thumbs/default.webp"
        style={{ width: "100%", borderRadius: "14px" }}
      />
      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
        Title of the exercise
      </div>
      <div
        style={{
          fontWeight: "normal",
          fontSize: "12px",
          color: "#A8B0BA",
        }}
      >
        Second line
      </div>
    </div>
  );
}

import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Tab3.css";

const Tab3: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen></IonContent>
    </IonPage>
  );
};

export default Tab3;
